/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import API from '../API';
import { getSessionStorageWithExpiry, setSessionStorageWithExpiry } from './Helper';

const Iframe = (props) => {

    const [iframeProps, setiframeProps] = useState({
        title: "dymamiciframe"
    });

    const [iframeSrc, setiframeSrc] = useState('');

    useEffect(() => {

        const fetchIframe = async () => {
            try {

                // setSessionStorageWithExpiry getSessionStorageWithExpiry

                let key = props.src

                if (key[0] == "/") {
                    key = key.replace("/", "")
                }

                // get cached data from session  
                const cachedData = getSessionStorageWithExpiry(key)

                if (cachedData) {

                    setiframeProps({
                        ...props,
                        src: cachedData
                    })
                    setiframeSrc(cachedData);

                } else {

                    const response = await API.post('/generate-presigned-url', { key, expiry: process.env.REACT_APP_URL_CACHE_EXPIRY_IN_WORDS })

                    setiframeProps({
                        ...props,
                        src: response.data.url
                    })
                    setiframeSrc(response.data.url);

                    // set cached data from session  
                    setSessionStorageWithExpiry(key, response.data.url, process.env.REACT_APP_URL_CACHE_EXPIRY)           // time is in seconds : (24 * 60 * 60)
                }

            } catch (error) {
                console.log(error);
            }
        };

        fetchIframe();
    }, []);

    return iframeSrc && <iframe {...iframeProps} />
};

export default Iframe;

