/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import API from '../API';
import { getSessionStorageWithExpiry, setSessionStorageWithExpiry } from './Helper';

const Img = (props) => {

    const [imgprops, setImgprops] = useState({
        alt: "dymamicimg"
    });

    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {

        const fetchImage = async () => {
            try {

                // setSessionStorageWithExpiry getSessionStorageWithExpiry

                let key = props.src

                if (key[0] == "/") {
                    key = key.replace("/", "")
                }

                // get cached data from session  
                const cachedData = getSessionStorageWithExpiry(key)

                if (cachedData) {

                    setImgprops({
                        ...props,
                        src: cachedData
                    })
                    setImageSrc(cachedData);

                } else {

                    const response = await API.post('/generate-presigned-url', { key, expiry: process.env.REACT_APP_URL_CACHE_EXPIRY_IN_WORDS })

                    setImgprops({
                        ...props,
                        src: response.data.url
                    })
                    setImageSrc(response.data.url);

                    // set cached data from session  
                    setSessionStorageWithExpiry(key, response.data.url, process.env.REACT_APP_URL_CACHE_EXPIRY)           // time is in seconds : (24 * 60 * 60)
                }

            } catch (error) {
                console.log(error);
            }
        };

        fetchImage();
    }, []);

    return imageSrc ? <img {...imgprops} /> : <img alt={props.title ? props.title : ""} />
};

export default Img;

