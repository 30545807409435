import React, { useContext, useEffect, useState } from "react";
import API from "../API";
import { ShowContext } from "../App";
import { de } from "../utils/Helper";

const DignitoryPhotos = () => {
  const { setShow, setMsg } = useContext(ShowContext);
  const [photos, setPhotos] = useState();

  useEffect(() => {
    getDignitoriesPhotos(setPhotos, setShow, setMsg);
  }, []);

  return (
    <div className="row ImagesPoliticians">
      {photos &&
        photos.map((photo, index) => (
          <div className="col-lg-2 col-sm-3 col-12 mb-3" key={index}>
            <div className="card h-100">
              <div className="text-center removerborder">
                <img
                  className="img-fluid "
                  src={photo.img_path}
                  alt={photo.name}
                  loading="lazy"
                />
              </div>
              <div className="details_auth">
                <span className="names">{photo.name}</span>
                <span className="names1">{photo.post}</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

function getDignitoriesPhotos(setPhotos, setShow, setMsg) {
  API.get("photo")
    .then((res) => {
      if (res.data.status === "success") {
        setPhotos(JSON.parse(de(res.data.data)));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default DignitoryPhotos;
