import React from "react";
import Iframe from "../../utils/Iframe";

function Affilation() {
    return (
        <>
            <div className="row innermain">
                <div className="col-sm-12">
                    <div className="card mb-3 borderforcard paddingTopxl">
                        <div className="card-header backgroundColorCardHeader">
                            <h1 className="fontForHeader">Affilation</h1>
                        </div>
                        <a
                            href="https://doaonline.in/institute-login"
                            className="links text-center p-2 mt-2"
                        >
                            Click here for affilation registration form
                        </a>
                        <div className="row g-0">
                            <div className="card-body removePadding row">
                                <div className="col-lg-12 col-sm-12">
                                    <div className="pdfDiv">
                                        <Iframe src={"/files/affiliation2024.pdf"} title="PDF" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Affilation;
