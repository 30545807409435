import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import Img from '../utils/Img';


const Corousel = () => {
    return (
        <div className='carousel-container'>
            <Swiper
                spaceBetween={30}
                effect={'fade'}
                navigation={false}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectFade, Navigation, Pagination, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <Img src={"/Images1/1.webp"} alt="Nature 1" className="CarousalImg banner-image" />
                </SwiperSlide>
                <SwiperSlide>
                    <Img src={"/Images1/sir-j-j-school-of-art-23.jpg"} alt="Nature 1" className="CarousalImg banner-image" />
                </SwiperSlide>
                <SwiperSlide>
                    <Img src={"/Images1/img2.jpg"} alt="Nature 2" className="CarousalImg banner-image" />
                </SwiperSlide>
                <SwiperSlide>
                    <Img src={"/Images1/about-jj-0.jpg"} alt="Nature 1" className="CarousalImg banner-image" />
                </SwiperSlide>
                <SwiperSlide>
                    <Img src={"/Images1/img1.jpg"} alt="Nature 3" className="CarousalImg banner-image" />
                </SwiperSlide>
            </Swiper>
            <div className="silderDiv">
                <h2>MSBAE Conducts</h2>
                <div className="content-box">
                    <ul className="m-0 fw-semibold px-3">
                        <li className="bg-transparent border-0 p-0">
                            Higher Art Examination
                        </li>
                        <li className="bg-transparent border-0 p-0">
                            Drawing Grade Examination
                        </li>
                        <li className="bg-transparent border-0 p-0">
                            Elementary & Intermediate for School Students
                        </li>
                        <li className="bg-transparent border-0 p-0">
                            Diploma / Certificate Courses
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Corousel;
