import React, { useEffect, useState } from "react";
import Corousel from "./Banner";
import DignitoryPhotos from "./DignitoryPhotos";
import EventCorousel from "./EventCorousel";
import AboutSection from "./aboutsection";
import ArtGallery from "./gallery";
import News from "./news";
// import 'animate.css';
import Img from "../utils/Img";

function Main() {
  const [isReadMore, setIsReadMore] = useState(false);

  useEffect(() => {
    // Set up IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add the animate.css classes when in view
            entry.target.classList.add(
              "animate__animated",
              "animate__fadeInUp",
              "animate__bounceIn"
            );
          }
        });
      },
      {
        threshold: 0.2, // Animation is triggered when 20% of the component is visible
      }
    );

    // Select all elements you want to animate on scroll
    const elementsToAnimate = document.querySelectorAll(".animate-on-scroll");

    // Attach observer to each element
    elementsToAnimate.forEach((el) => observer.observe(el));

    // Cleanup observer on unmount
    return () => {
      elementsToAnimate.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const longText =
    "To enhance Art Education Governance, the Maharashtra Government has established Maharashtra State Board of Art Education under Maharashtra Act, XII. This is published in the Government Gazette on January 19, 2024, and officially came into effect on February 23, 2024.";
  const longText2 =
    "The Maharashtra State Board of Art Education is established to regulate the matters pertaining to diploma level art education in the state of Maharashtra.  Government, Non-Government Aided and Unaided Institutes are affiliated to Maharashtra State Board of Art Education. Our mission is to create a vibrant Art Education environment that fosters creativity and innovation. We aim to ensure effective regulation and support for our institutions, ultimately raising the standards of art education across the state.";

  const handleReadMoreClick = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <div className="">
        <div className="section">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-sm-12 ">
              <Corousel />
            </div>
          </div>
        </div>
        {/* <LatestUpdates /> */}
        <div className="container">
          <div className="DignitoryPhotosdesk  animate__animated animate-on-scroll justify-content-center mt-3 pt-5">
            <DignitoryPhotos />
          </div>
        </div>

        {/* DIRECTOR'S DESK */}
        <div className="desk-section my-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-5">
                <div className="color-bg">
                  <div className="diretorImg animate-on-scroll">
                    <div className="my-2">
                      <Img
                        className="img-fluid animate__animated animate__bounceIn"
                        src={"/Images1/DirectorMSBAE.png"}
                        alt="Shri. Vinod R. Dandge"
                      />
                      <p className="names">Shri. Vinod R. Dandge</p>
                      <p>~~~~~~~~~</p>
                      <p className="names3">
                        Director of Maharashtra State Board of Art Education,
                        Mumbai
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-7">
                <div className="dDesc animate-on-scroll">
                  <div className="p-2">
                    <div className="text-left text-dark newtitle">
                      <h4>FROM DIRECTOR'S DESK</h4>
                    </div>
                    <p className="fontForDesk" id="fontfordirectordesk">
                      {longText}
                      {isReadMore && <span>{longText2}</span>}
                    </p>
                    <button
                      className="more-button mt-3"
                      onClick={handleReadMoreClick}
                    >
                      {isReadMore ? "Read Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center gap-md-4 my-3 py-3">
          <div className="col-lg-6 col-sm-12 mb-3 animate-on-scroll">
            <News />
          </div>
          <div className="col-lg-4 col-sm-12 mb-3 animate-on-scroll">
            <div className="mx-3">
              <EventCorousel />
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 col-sm-12">
            <AboutSection />
          </div>
        </div>
        <div className="row justify-content-center">
          <ArtGallery />
        </div>
      </div>
    </>
  );
}

export default Main;
