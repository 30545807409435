/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ShowContext } from "../App";
import { de } from "../utils/Helper";
import API from "../API";

const RegionalOffice = () => {

    const { setShow, setMsg } = useContext(ShowContext);

    const { region } = useParams()

    const [data, setData] = useState();

    useEffect(() => {
        getRegionData(region)
    }, [region]);

    function capitalizeFirstLetter(word) {
        if (!word) return '';
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    return <div>
        <div>
            <div className="card mb-3 borderforcard paddingTopxl">
                <div className="card-header backgroundColorCardHeader linearimg">
                    <h1 className="fontForHeader">{capitalizeFirstLetter(region)} Region</h1>
                </div>
                <br />
                <div className="table-responsive">
                    <table className="table table-bordered region-table">
                        <thead className="thead-dark">
                            <tr>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>E-mail</th>
                                <th>Officers Image</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data && data.map((item, i) => <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.post}</td>
                                <td className="text-center">
                                    <a href={"mailto:" + item.email}>{item.email}</a>
                                </td>
                                <td className="text-center">
                                    <img src={item.img_path} alt={item.name} />
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    function getRegionData(region) {
        API.get("regional-office", { params: { region } })
            .then((res) => {
                if (res.data.status === "success") {
                    setData(JSON.parse(de(res.data.data)));
                    console.log(JSON.parse(de(res.data.data)));

                }
            })
            .catch((error) => {
                setData()
                setShow(true);
                setMsg(error.response.data.message);
            });
    }
};

export default RegionalOffice;
