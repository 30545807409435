/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import API from "../API";
import { ShowContext } from "../App";
import { de } from "../utils/Helper";

const News = () => {
  const { setShow, setMsg } = useContext(ShowContext);
  const [circulars, setCirculars] = useState();
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const marqueeRef = useRef(null);

  const handleMouseEnter = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseLeave = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  useEffect(() => {
    getNews(setCirculars, setShow, setMsg);
  }, []);

  let i = 1;
  return (
    <>
      <div className="card border-0 shadow-0 m-3 bg-transparent ">
        <div className="newtitle">
          <h4 className="text-left  pb-3 mb-2 mainTitle"> Circular/Notices </h4>
        </div>
        <div className="card-body overflow-auto">
          <ul className="listIcon">
            {circulars &&
              circulars
                // .filter((circular) => circular.category === 1)
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .slice(0, 20)
                .map((circular, index) => (
                  <li className="circularsList" key={index}>
                    {circular.url.includes("pdf") ? (
                      <a
                        href="#"
                        className="py-1"
                        aria-disabled="true"
                        onClick={() => {
                          handleShow();
                          setFile(circular.url);
                        }}
                      >
                        {circular.heading}
                      </a>
                    ) : (
                      <a
                        href={circular.url}
                        className="py-1"
                        aria-disabled="true"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {circular.heading}
                      </a>
                    )}
                  </li>
                ))}
          </ul>
          {/* <marquee
            behavior="scroll"
            direction="up"
            scrollamount="2"
            loop="infinite"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={marqueeRef}
            className="circularBox"
          >
            <ul className="listIcon">
              {circulars &&
                circulars
                  // .filter((circular) => circular.category === 1)
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .slice(0, 20)
                  .map((circular, index) => (
                    <li className="circularsList" key={index}>
                      {circular.url.includes('pdf')
                        ? <a
                          href="#"
                          className="py-1"
                          aria-disabled="true"
                          onClick={() => {
                            handleShow();
                            setFile(circular.url);
                          }}
                        >
                          {circular.heading}
                        </a>
                        : <a
                          href={circular.url}
                          className="py-1"
                          aria-disabled="true"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {circular.heading}
                        </a>
                      }
                    </li>
                  ))}
            </ul>
          </marquee> */}
          <button className="more-button mt-3">Read More</button>
        </div>
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          contentClassName="modal-pdf-content"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body closeButton>
            <iframe title="myFrame" className="w-100 h-100" src={file}></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

async function getNews(setCirculars, setShow, setMsg) {
  API.get("news")
    .then((res) => {
      if (res.data.status === "success") {
        setCirculars(JSON.parse(de(res.data.data)));
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default News;
