import React from "react";
import Iframe from "../../utils/Iframe";

function Institute() {
  return (
    <>
      <div className="row innermain">
        <div className="col-sm-12">
          <div className="card mb-3 borderforcard paddingTopxl">
            <div className="card-header backgroundColorCardHeader">
              <h1 className="fontForHeader">Institutes</h1>
            </div>
            <div className="card-body removePadding row">
              <div className="col-lg-12 col-sm-12">
                <div className="pdfDiv">
                  <Iframe src={"/files/institutes.pdf"} title="PDF" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Institute;
