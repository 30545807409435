import React from "react";
import Img from "../utils/Img";

// import "animate.css"; // Import animate.css for animation
// import "./ArtGallery.css"; // Import your custom CSS

const ArtGallery = () => {
  return (
    <section className="art-gallery py-5">
      <div className="mx-4">
        <div className="newtitle text-center">
          <h4 className=" py-4 mainTitle">Art Gallery</h4>
        </div>

        <p className="text-center mb-3">
          Our mission is to create a vibrant Art Education environment that
          fosters creativity and innovation.
        </p>
      </div>
      <div className="marquee">
        <div className="marquee-content pt-3">
          {galleryImages.map((image, index) => (
            <div className="marquee-item" key={index}>
              <Img className="img-fluid" src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const galleryImages = [
  { src: "/Images1/gallery1.webp", alt: "gallery images" },
  { src: "/Images1/gallery2.jpg", alt: "gallery images" },
  { src: "/Images1/gallery3.jpeg", alt: "gallery images" },
  { src: "/Images1/gallery4.png", alt: "gallery images" },
  { src: "/Images1/gallry5.webp", alt: "gallery images" },
  { src: "/Images1/gallery6.webp", alt: "gallery images" },
  { src: "/Images1/Gallry7.jpeg", alt: "gallery images" },
  { src: "/Images1/Gallery8.jpg", alt: "gallery images" },
  { src: "/Images1/gallery9.jpg", alt: "gallery images" },
  { src: "/Images1/Gallery10.jpg", alt: "gallery images" },
  { src: "/Images1/gallry11.jpg", alt: "gallery images" },
  { src: "/Images1/gallery12.jpeg", alt: "gallery images" },
];

export default ArtGallery;
