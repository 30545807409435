import React from "react";
import Iframe from "../../utils/Iframe";

function Examination() {
  return (
    <>
      {/* <div className="commonSubheader">
        <Container>
          <Row>
            <Col lg={12}>
              <h3 className="innercommTitle"></h3>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="row innermain">
        <div className="col-sm-12">
          <div className="card mb-3 borderforcard paddingTopxl">
            <div className="card-header backgroundColorCardHeader">
              <h1 className="fontForHeader">Examination</h1>
            </div>
            {/* <div className="card-header backgroundColorCardHeader">
              <h1 className="fontForHeader">Affilation</h1>
            </div> */}
            <a
              href="https://dge.msbae.in/"
              className="links text-center p-2 mt-2"
            >
              Click here for center registration and student registration
            </a>
            <div className="row g-0">
              <div className="card-body">
                <div className="pdfDiv">
                  <Iframe src={"/files/drawing-exam.pdf"} title="PDF" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Examination;
